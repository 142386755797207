<template>
  <section class="main">
    <el-form
      :label-position="'left'"
      label-width="150px"
      @submit.prevent="onSubmit"
      style="margin: 20px; width: 60%; min-width: 600px"
    >
      <el-form-item label="subjectCourseId">
        <el-input v-model="simple.subjectCourseId"> </el-input>
      </el-form-item>
      <el-form-item label="id">
        <el-input v-model="simple.id" disabled> </el-input>
      </el-form-item>
      <el-form-item label="试卷标题">
        <el-input v-model="simple.title"> </el-input>
      </el-form-item>
      <el-form-item label="试卷类型">
        <el-select v-model="simple.tikuType" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="有权限的课程">
        <el-input v-model="courseListStr"> </el-input>
      </el-form-item>
      <el-form-item label="pdf">
        <uploadSingle v-model="pdfCos" :cosPrefix="cosPrefix" :readonly="false"> </uploadSingle>
      </el-form-item>
      <el-form-item label="word">
        <uploadSingle v-model="wordCos" :cosPrefix="cosPrefix" :readonly="false"> </uploadSingle>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSave">保存</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { cosApi } from "@/api/cosApi";
import { tikuApi } from "@/api/tikuApi";
import { epochToDateString } from "@/util/TimeUtil";
import msgLogger from "@/util/msgLogger";
import COS from "cos-js-sdk-v5";
import uploadSingle from "@/component/uploadSingle.vue";

export default {
  components: {
    uploadSingle,
  },
  data() {
    return {
      options: [
        { label: "真题", value: 0 },
        { label: "套卷", value: 1 },
      ],
      pdfCos: {
        key: "",
        url: "",
      },
      wordCos: {
        key: "",
        url: "",
      },
      cosPrefix: "",
      courseListStr: "",
      simple: {
        subjectCourseId: 0,
        id: 0,
        tikuType: 0, // 0:真题，1:模块刷题
        title: "",
        pdfKey: "",
        pdfUrl: "",
        wordKey: "",
        wordUrl: "",
      },
    };
  },
  mounted() {
    tikuApi.simpleTikuCosPrefix().then((ret) => {
      let { code, data } = ret;
      this.cosPrefix = data;
      console.log("cosPrefix", this.cosPrefix);
    });
    if (this.$route.query && this.$route.query.subjectCourseId && this.$route.query.id) {
      const subjectCourseId = this.$route.query.subjectCourseId;
      const id = this.$route.query.id;
      this.renderItem(subjectCourseId, id);
    }
  },
  methods: {
    onSave() {
      if (this.simple.subjectCourseId <= 0) {
        msgLogger.error("subjectCourseId 必须大于 0");
        return;
      }
      if (!this.simple.title) {
        msgLogger.error("title 必填 ");
        return;
      }
      this.simple.pdfKey = this.pdfCos.key;
      this.simple.wordKey = this.wordCos.key;
      this.simple.courseList = this.courseListStr.split(",");
      console.log("on save", this.simple);
      tikuApi
        .simpleTikuAddEdit(this.simple)
        .then((ret) => {
          let { code, data } = ret;
          if (code == 0) {
            this.simple = data;
            msgLogger.done("保存成功");
          }
        })
        .catch((err) => {
          msgLogger.error("出错了" + err);
        });
    },
    renderItem(subjectCourseId, id) {
      tikuApi
        .simpleTikuGet(subjectCourseId, id)
        .then((ret) => {
          console.log("get", ret);
          let { code, data } = ret;
          if (code == 0) {
            this.simple = data;
            this.pdfCos = {
              key: this.simple.pdfKey,
              url: this.simple.pdfUrl,
            };
            this.wordCos = {
              key: this.simple.wordKey,
              url: this.simple.wordUrl,
            };
            if (this.simple.courseList) {
              this.courseListStr = this.simple.courseList.join(",");
            }
          }
        })
        .catch((err) => {
          msgLogger.error("出错了" + err);
        });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped></style>
