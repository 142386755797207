<template>
  <div class="upload">
    <div class="img" @click="viewFile()" v-if="cos.url">
      <a :href="cos.url" target="_blank"> {{ cos.key }} </a>
      <img class="del-icon" :src="delIcon" alt="" @click.stop="delFile()" v-if="readonly == false" />
    </div>

    <div class="add-image" @click="uploadImg" v-if="isUploading == false && readonly == false">
      <img :src="addIcon" alt="" />

      <input ref="image" type="file" name="" id="fileInput" style="display: none" @change="handleUpload" />
    </div>
    <!-- <div class="add-progress" v-show="isUploading">
      {{ totalPercent }}
    </div> -->
  </div>
</template>

<script>
import { fileValidate } from "@/util/fileValidate";
import { cosApi } from "@/api/cosApi.js";
import { tikuApi } from "@/api/tikuApi.js";
import COS from "cos-js-sdk-v5"; //脚手架安装
import { nanoid } from "nanoid";
import msgLogger from "@/util/msgLogger";
export default {
  components: {},
  model: {
    prop: "cos",
    event: "onAddMedia", // v-model
  },
  props: {
    cos: Object, // key, url,
    cosPrefix: String,
    readonly: Boolean,
  },
  data() {
    return {
      addIcon: require("@/assets/images/upload.png"),
      delIcon: require("@/assets/images/del-img.png"),
      bucket: "",
      region: "",
      fileDoneCnt: 0,
      cosStsUrl: "",

      isUploading: false,
      todoFiles: [], //上传和接收回调
      totalPercent: 0,

      curCos: {
        key: "",
        url: "",
      },
    };
  },
  methods: {
    //删除图片
    delFile() {
      let newCos = {
        key: "",
        url: "",
      };
      this.$emit("onAddMedia", newCos); //emit 事件，父组件更新，再传给子组件
    },
    viewFile() {
      //window.open(item.url, "_blank");
    },
    getBucketRegion() {
      cosApi
        .getBucketRegion("private")
        .then((ret) => {
          console.log("getBucket", ret);
          let data = ret.data;
          this.bucket = data.bucket;
          this.region = data.region;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCosStsUrl() {
      const url = cosApi.COS_STS_URL;
      this.cosStsUrl = url;
    },
    // 点击请求input的click()事件
    uploadImg() {
      this.$refs.image.click();
    },
    // 用户选择好文件
    handleUpload(e) {
      let _this = this;
      console.log(e.target.files, "上传文件");
      //this.getCosPrefix(); 在mounted初始化就行了，不需要再取一次了
      var cosClient = new COS({
        getAuthorization: function(options, callback) {
          //const COS_STS_URL = `/api/itedu/v1/cos/appid`;
          console.log("sts url", _this.cosStsUrl);
          var url = _this.cosStsUrl;
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onload = function(e) {
            try {
              var data = JSON.parse(e.target.responseText);
              var credentials = data.credentials;
            } catch (e) {
              console.log(e);
            }
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              XCosSecurityToken: credentials.sessionToken,
              ExpiredTime: data.expiredTime,
            });
          };
          xhr.send();
        },
      });
      const maxsize = 3000 * 1024 * 1024; // 3gb
      let userSelectFile = e.target.files[0];
      if (
        fileValidate.matchFileType(userSelectFile.name) != "pdf" &&
        fileValidate.matchFileType(userSelectFile.name) != "word"
      ) {
        msgLogger.error("文件格式错误，请重新上传!");
        return;
      }
      if (userSelectFile.size > maxsize) {
        msgLogger.error("文件大小错误，请重新上传!");
        return;
      }
      let newKey = this.cosPrefix + "/" + nanoid(8) + "_" + userSelectFile.name;

      _this.isUploading = true;

      const slice = cosClient.sliceUploadFile(
        {
          Bucket: this.bucket,
          Region: this.region,
          Key: newKey,
          Body: userSelectFile,
          onTaskReady: function(taskId) {
            /* 非必须 */
            console.log(taskId);
          },
          onHashProgress: function(progressData) {
            /* 非必须 */
            console.log(JSON.stringify(progressData));
          },
          onProgress: function(progressData) {
            /* 非必须 */
            console.log("..progress..", JSON.stringify(progressData));
          },
        },
        function(err, data) {
          console.log("...", err, data);
          if (data.statusCode == 200) {
            // 上传完成
            console.log("..done..", data);
            console.log("..key..", data.Key); // cos key 在这
            tikuApi.simpleTikuCdnUrl(data.Key).then((ret) => {
              console.log("url", ret); // cdn url 在这
              let newCos = {
                key: data.Key,
                url: ret.data,
              };
              console.log("....emit....", newCos);
              _this.$emit("onAddMedia", newCos);
              _this.isUploading = false;
            });
          }
        }
      );
    },
  },
  mounted() {
    this.getBucketRegion();
    this.getCosStsUrl();
    //console.log("this.attach", this.attach);
  },
  watch: {},
  destroyed() {},
  filters: {},
};
</script>

<style lang="less" scoped>
.upload {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .img {
    height: 54px;
    width: 454px;
    margin: 12px 12px 0px 0px;
    position: relative; // 父节点要设置relative
    border-radius: 8px;
    overflow: hidden;

    .del-icon {
      position: absolute; // 相对于 static 定位以外的第一个父元素进行定位,如果找不到父级元素，那么相对于浏览器窗口进行定位 。
      z-index: 1;
      top: 0px;
      right: 0px;
      width: 16px;
      height: 16px;
    }
  }
  .add-image {
    height: 54px;
    width: 54px;
    margin: 12px 12px 0px 0px;
    img {
      height: 54px;
      width: 54px;
    }
  }
  .add-progress {
    height: 54px;
    width: 54px;
    margin: 12px 12px 0px 0px;
    background: #d8d8d8;
    border-radius: 8px;
    color: #505051;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
