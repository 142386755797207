const matchFileType = (fileName) => {
  // 后缀获取
  let suffix = "";
  // 获取类型结果
  let result = "";
  if (!fileName) return false;
  try {
    // 截取文件后缀
    suffix = fileName.substr(fileName.lastIndexOf(".") + 1, fileName.length);
    // 文件后缀转小写，方便匹配
    suffix = suffix.toLowerCase();
  } catch (err) {
    suffix = "";
  }
  // fileName无后缀返回 false
  if (!suffix) {
    result = false;
    return result;
  }
  let fileTypeList = [
    // 图片类型
    { typeName: "image", types: ["png", "jpg", "jpeg", "bmp", "gif"] },
    // 文本类型
    { typeName: "txt", types: ["txt"] },
    // excel类型
    { typeName: "excel", types: ["xls", "xlsx"] },
    { typeName: "word", types: ["doc", "docx"] },
    { typeName: "pdf", types: ["pdf"] },
    { typeName: "ppt", types: ["ppt", "pptx"] },
    // 视频类型
    { typeName: "video", types: ["mp4", "m2v", "mkv", "avi", "wmv", "mpg", "mpeg", "mov", "flv"] },
    // 音频
    { typeName: "audio", types: ["mp3", "wav", "wmv"] },
    // 压缩包
    { typeName: "zip", types: ["zip", "rar", "7z"] },
  ];
  // let fileTypeList = ['image', 'txt', 'excel', 'word', 'pdf', 'video', 'radio']
  for (let i = 0; i < fileTypeList.length; i++) {
    const fileTypeItem = fileTypeList[i];
    const typeName = fileTypeItem.typeName;
    const types = fileTypeItem.types;
    // console.log(fileTypeItem);
    result = types.some(function(item) {
      return item === suffix;
    });
    if (result) {
      return typeName;
    }
  }
  return "other";
};

const getFilePostFix = (fileName) => {
  // 后缀获取
  let suffix = "";
  // 获取类型结果
  let result = "";
  if (!fileName) return false;
  try {
    // 截取文件后缀
    suffix = fileName.substr(fileName.lastIndexOf(".") + 1, fileName.length);
    // 文件后缀转小写，方便匹配
    suffix = suffix.toLowerCase();
  } catch (err) {
    suffix = "";
  }
  // fileName无后缀返回 false
  if (!suffix) {
    result = false;
    return result;
  }
};

export const fileValidate = {
  matchFileType: matchFileType,
  getFilePostFix: getFilePostFix,
};
